/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { Link } from "gatsby";
import React from "react";
import LayoutHome from "../layout/LayoutHome";

const ShriRamChandraMission = () => {
  return (
    <LayoutHome
      seoTitle="Shri Ram Chandra Mission USA -Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Shri Ram Chandra Mission USA</h3>}
    >
      <React.Fragment>
        <p sx={{ mt: 2 }}>Voluntary Donation – Options to donate</p>
        <Box>
          <h4 sx={{ color: "#777777" }}>Checks</h4>
          <p>
            Checks should be payable to “Shri Ram Chandra Mission” and mailing
            it to the following address
          </p>
          <p>
            Shri Ram Chandra Mission
            <br />
            P O Box 3413
            <br />
            Princeton NJ 08543
          </p>
        </Box>
        <Box>
          <h4 sx={{ color: "#777777" }}>Credit Card</h4>
          <p>
            All credit card donations using the following link are for SMSF.
          </p>
          <p>
            You can make an Online Donation using your Credit Card at the
            following Link:
            <Link to="/usabhyasis">
              https://donations.heartfulness.org/usabhyasis
            </Link>
          </p>
        </Box>
        <Box>
          <h4 sx={{ color: "#777777" }}>Bank Transfer</h4>
          <p>
            You can also donate using money transfer from your bank directly to
            SRCM
          </p>
          <p>
            Account No.: 003255784508
            <br />
            Account Name: Shri Ram Chandra Mission
            <br />
            ACH: 061000052
            <br />
            Address: PO Box 3413, Princeton NJ 08543
            <br />
            Please send an email to{" "}
            <a href="mailto:us.treasurer@srcm.org">
              us.treasurer@srcm.org
            </a>{" "}
            after the transfer is complete.
          </p>
        </Box>
        <Box>
          <h4 sx={{ color: "#777777" }}>Wire Transfer</h4>
          <p>
            You can also donate using wire transfer from your bank directly to
            SRCM
          </p>
          <p>
            Account No.: 003255784508
            <br />
            Account Name: Shri Ram Chandra Mission
            <br />
            Routing Number: 061000052
            <br />
            Address: PO Box 3413, Princeton NJ 08543
            <br />
            Please send an email to{" "}
            <a href="mailto:us.treasurer@srcm.org">
              us.treasurer@srcm.org
            </a>{" "}
            after the transfer is complete.
          </p>
        </Box>
        <Box>
          <h4 sx={{ color: "#777777" }}>Stocks</h4>
          <p>
            You can also donate stocks using stock transfer directly to SRCM
          </p>
          <p>
            Account No.: 7JS-02004
            <br />
            Acct Title: Shri Ram Chandra Mission
            <br />
            Receiving Firm: Merrill Lynch
            <br />
            DTC#: 5198-National Financial Services
          </p>
        </Box>
        <p>
          If you need any additional information please send an email to Dheeraj
          Tandon, SRCM USA Treasurer at{" "}
          <a href="mailto:us.treasurer@srcm.org ">us.treasurer@srcm.org </a> or
          you may call (917) 497 4628.
        </p>
      </React.Fragment>
    </LayoutHome>
  );
};

export default ShriRamChandraMission;
